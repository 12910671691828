body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  color: white;
  background-color: floralwhite;
  opacity: 1;
}
@font-face {
  font-family: SageMondial;
  src: url("../public/fonts/SageMondialRegular-EaKGj.ttf") format("opentype");
}
@font-face {
  font-family: UnitBlock;
  src: url("../public/fonts/Unitblock-mLAwm.ttf") format("opentype");
}
@font-face {
  font-family: Fonarto;
  src: url("../public/fonts/FonartoRegular-8Mon2.ttf") format("opentype");
}
@font-face {
  font-family: Yeezy;
  src: url("../public/fonts/yeezy_tstar-bold-webfont.ttf") format("opentype");
}

@font-face {
  font-family: Yeezy2;
  src: url("../public/fonts/VCR_OSD_MONO_1.001.ttf") format("opentype");
}
@font-face {
  font-family: Bavex;
  src: url("../public/fonts/Bavex.ttf") format("opentype");
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  width: 100dvw;
  /* font-family: UnitBlock; */
  top: 0;
  z-index: 2;
  height: calc(100dvh - 95%);
  background-color: transparent;
  position: sticky;
  display: flex;
  padding: 2rem
}
main {
  position: absolute;
  /* overflow: hidden; */
  height: 100dvh;
  width: 100dvw;
  font-family: Fonarto;
  transition: 2s;
  opacity: 1;
  top: 0;
}
main.off{
  opacity: 0;
}
main.loading{
  
}
body.off{
  opacity: 1;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: aqua;
}
h1{
  text-align: center;
  pointer-events: none;
  margin: 0;
  color: white;
}
img{  
  object-fit: cover;
}
::-webkit-scrollbar{
  background-color: white;
  border-radius: 50px;  
  backdrop-filter: blur(10px);
  height: 5px;
  scroll-snap-type: x mandatory;  
  width: 5px;
}

::-webkit-scrollbar-thumb{
  background-color: rgba(0, 0, 0, 0.452);
  backdrop-filter: blur(10px);
  border-radius: 50px;
  scroll-snap-type: x mandatory;
  
}
