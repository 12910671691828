.category-title {
    font-size: 10em;
     font-family: Bavex; 
    
    transition: 1s;
    letter-spacing: 15px;
    pointer-events:fill;
    text-align: center;
    justify-content: center;
    width: 100%;
    filter: drop-shadow(0px 0px 8px white);
    font-weight: 800;
  }
  .carousel-control-next{
    transition: opacity .15s ease;
    backdrop-filter: blur(1px);
    background: linear-gradient(to left, #00000082 5%, transparent 68%);
  }
  .carousel-control-prev{
    transition: opacity .15s ease;
    backdrop-filter: blur(1px);
    background: linear-gradient(to right, #00000082 5%, transparent 68%); 
  }
  .carousel-control-next:hover,.carousel-control-prev:hover{
    opacity: 0.5;
    
  }
  .carousel-control-next-icon, .carousel-control-prev-icon{
    filter: invert(0);
  }
  .carousel-indicators{
    bottom: 35px;
    
  }
  #circle {
  
    width: 10px;
    border-radius: 50%;
    height: 10px;
  }
  
  .category-title:hover {  
      letter-spacing: 20px; 
    
  }
  .carousel{
    width: 100%;
    height: 100dvh;
  }
  .carousel-caption{
    top: 45%;
  }
  .cover-p{
    filter: brightness(0.5);
  }
  @media (max-width: 800px)
  {
     .category-title{
    font-size: 6em;
    letter-spacing: 0px;
  }

  }
  @media (max-width: 500px)
  {
     .category-title{
    font-size: 4em;
  }

  }