.about {
  position: relative;
  height: 90dvh;
}
#about-container {
  opacity: 1;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}
.content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;

  justify-content: space-evenly;
  flex-wrap: wrap;
}

.profile-pic img {
  width: 100%;
  height: 90%;
}
.profile-pic,
.content {
  height: 100%;
}
.profile-pic {
  width: 500px;
  height: 100%;
  max-height: 800px;
}
.profile-fig {
  height: 100%;
  width: 100%;
}
.profile-fig img {
  width: 100%;
  height: 100%;
  transform-origin: left;
}
#user-name {
  transform-origin: left;
  margin-right: 20px;
  font-size: 6rem;
  font-family: Bavex;
  font-weight: 900;
}

.profile-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.content {
  width: 50%;
  opacity: 1;
  font-family: system-ui;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: 200;
  letter-spacing: 3px;
  position: relative;
  scroll-snap-type: y mandatory;
}
.content > section {
  height: 100%;
  opacity: 1;
  /* animation: appear linear forwards;
    animation-timeline: view(99% 5px); */
  /* scroll-snap-align: center; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.contact-info-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-height: 100px;
  align-items: center;
  position: sticky;
  bottom: 0;
}
.social-link-list {
  list-style: none;
  display: flex;
  gap: 15px;
}
.social-link-list a {
  text-decoration: none;
  color: black;
}
.unsplash_status {
  width: 100%;
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: center;
  gap: 10px;
  padding: 0;
  margin: 0;
}
.unsplash_status li {
  border-left: 1px solid gray;
  padding: 15px;
  margin: 0;
}
.unsplash_status li p {
  font-size: 50px;
}
@keyframes scaley {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
@keyframes appear {
  from {
    transform: translateX(320px);
    -webkit-transform: translateX(320px);
    scale: 0.7;
    opacity: 0;
  }
  to {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1;
    scale: 1;
  }
}

.bio {
  width: 100%;
  padding: 5px;
  transform-origin: left;
  font-size: 1.1em;
  letter-spacing: 1.2px;
}
.unsplash_status li p {
  font-family: Fonarto;
}
@media (max-width: 1050px) {
  .bio {
    width: 90%;
    font-size: medium;
  }
  .content > section {
    align-items: flex-start;
  }
}
@media screen and (max-width: 1000px) {
  .content {
    justify-items: center;
    width: 90%;
  }
  .content > section {
    width: 90%;
    height: 50%;
  }
  .profile-pic {
    height: 30%;
    width: 400px;
    padding: 10px;
  }
  .profile-pic img {
    object-fit: cover;
    transform: scaleX(1);
    animation: none;
  }
}
@media screen and (max-width: 1000px) {
  .content {
    width: 90%;
    height: 50%;
  }
  
  .unsplash_status li p, .unsplash_status li span {
    font-size: 15px;
  }
  .unsplash_status li{
    padding: 3px
  }
  .unsplash_status {
    gap: 0;
    justify-content: flex-start;
  }
}
@media (max-width: 500px) {
  .profile-pic {
    height: 30%;
    width: 300px;
    padding: 10px;
  }
}
@media screen and (max-width: 1000px) and (max-height: 400px) {
  .profile-pic {
    height: 150px;
    width: 150px;
    padding: 10px;
  }
}
