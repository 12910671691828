
.App{  
  opacity: 1;
}
.App.off{
  opacity: 0;
}
/* HEADER */
#top-nav-links {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  margin: 0;
  height: 100%;
  align-items: center;
  gap: 20px;
  width: 100%;
  
}
#top-nav-links a {
  color: currentColor;
  text-decoration: none;
 
  border-bottom: 0px solid white;
  transition: 150ms ease-in-out;
  /* filter: drop-shadow(4px 2px 4px black); */
  
}

#top-nav-links a:hover{
  /* filter: drop-shadow(0px 0px 2px); */
  color: rgb(204, 204, 57);
}
#top-nav-links h4{
  font-weight: 100;
}


.load-screen{
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-backdrop-filter: blur(15px);
   backdrop-filter: blur(15px); 
  background-color: #f5f5f593;
}
.load-screen.off{
  display: none;
}
@media (max-width: 1050px){
  .category-choice-list{
    display: none;
  }
}

