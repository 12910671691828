.container-photos{
  width: 100%;
  height: 90%;
  display: flex;
  color: black;
  justify-content: flex-start;  
  margin-top: 10px;
  margin-bottom: 30px;
}
main.photos{
  position: sticky;
  height: 95dvh;
}
.picture-list{
    list-style: none;
    padding: 0 10px;
    margin: 0;
    display: flex;
    gap: 5px;
    overflow: auto;
    
    
    justify-content: center;
    
    width: 80%;
    
  }
  .picture-list1x1{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 10px;
    overflow: auto;
    flex-wrap: wrap;
    justify-content: center;
    
    width: 80%;
  }
  .picture-list1x1 img {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .mygrid_vertical3x3{ 
    display: flex;
    flex-direction: column;
    gap: 10px;
     
    max-width: 30dvw;
    align-items: center;
  }

  .mygrid_vertical1x1{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 400px;
    align-items: center;
    width: 90%;
  }
  .picture-list img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: 250ms ease-in-out;
  }
  .picture-list img:hover{
    filter: drop-shadow(2px 4px 6px black);
  }
  
  .category-choice-list{
    
    padding: 0;
    margin: 0;
    display: flex;
    width: -moz-fit-content;
    width: 30%;
    max-width: 300px;
    min-width: 275px;
    transition: 350ms ease-in-out;
    
  }
  .category-choice-dropdown{
    display: none;
    
  }
  .category-choice-dropdown > button{
    border: 0.5px solid;
    border-radius: 5px;
    background-color: white;
  }
  .category-choice-list > ul{
    list-style: none;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: larger;
  }
 
  .category-choice-list > ul > li{  
    font-family: Bavex; 
    width: 90%;
    padding: 0;
  }
  .category-choice-list .mbtn{
    position: relative;
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
    padding: 4px;
    border-radius: 10px;
    font-family: sans-serif;
    font-size: 1rem;
  }
  .category-choice-list .mbtn:hover{
    color: rgb(99, 91, 24);
  }
  .category-choice-list .mbtn:active{
    color: aqua;
  }
  .category-choice-list.off{
    transform: scaleX(0);
    
  }
  
  @media screen and (max-width:500px){
    .category-choice-dropdown{
      display: block;
    }
    .category-choice-list{
      position: absolute;
      background-color: white;
      transform: scaleX(1);
      
    }

  .container-photos {
    justify-content: center;
  }
  }